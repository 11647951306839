import React, { useState, useEffect } from "react";
import styled from "styled-components";

// https://web.dev/customize-install/
export const A2HSProvider = () => {
  const [showInstallPromotion, setShowInstallPromotion] = useState(false);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  const hasBeenPromptedBefore = () => window.localStorage.a2hsPromptShown;

  useEffect(() => {
    if (!isInStandaloneMode() && isIos() && !hasBeenPromptedBefore()) {
      setShowInstallPromotion(true);
      return;
    }
  }, []);

  const handleClick = async () => {
    window.localStorage.a2hsPromptShown = "true";
    setShowInstallPromotion(false);
  };

  const handleClose = () => {
    setShowInstallPromotion(false);
  };

  return (
    <React.Fragment>
      {showInstallPromotion && (
        <A2HSButton onClick={handleClick}>
          <div className="logo">
            <svg
              enableBackground="new 0 0 1952.00 734.93"
              height="25"
              viewBox="0 0 1952 734.93"
              width="70"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g strokeLinejoin="round" strokeWidth=".2">
                <path
                  d="m1436.62 603.304 56.39-142.599h162.82l-77.27-216.315 96.64-244.38947166 276.8 734.93247166h-204.13l-47.3-131.629z"
                  fill="#3d3d3d"
                />
                <path
                  d="m1262.47 734.935 296.32-734.93343407-196.45.00097657-202.7 474.9304575-144.14-474.92948094h-151.001l-154.768 474.92948094-109.146-216.416-98.773 304.302 100.284 172.116h193.331l139.857-425.91 133.346 425.91z"
                  fill="#5a0fc8"
                />
                <path
                  d="m186.476 482.643h121.003c36.654 0 69.293-4.091 97.917-12.273l31.293-96.408 87.459-269.446c-6.664-10.5625-14.272-20.5493-22.824-29.9591-44.905-49.7049-110.605-74.55649373-197.102-74.55649373h-304.22200381v734.93259373h186.47600381zm160.166-313.564c17.54 17.653 26.309 41.276 26.309 70.871 0 29.822-7.713 53.474-23.138 70.956-16.91 19.425-48.047 29.137-93.409 29.137h-69.928v-197.445h70.442c42.277 0 72.185 8.827 89.724 26.481z"
                  fill="#3d3d3d"
                />
              </g>
            </svg>
          </div>
          <div className="title">
            Asenna tämä sovellus iOS-laitteellesi: paina
            <svg
              className="ios-share-icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" />
            </svg>
            ja lisää aloitusnäytölle.
          </div>
          <div className="close-button" onClick={handleClose}>
            <svg
              fill="#000000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
            >
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
            </svg>
          </div>
        </A2HSButton>
      )}
    </React.Fragment>
  );
};

export default A2HSProvider;

const A2HSButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  box-sizing: border-box !important;
  box-shadow: #e9e9e9 0 0 10px;
  background-color: #f9f9f9;
  padding: 10px;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2022;

  & * {
    box-sizing: border-box;
  }

  & .logo {
    padding: 10px;
  }

  & .title {
    font-size: 0.9rem;
    color: #5d5d5d;
  }

  .close-button {
    margin-left: 16px;
  }
  .ios-share-icon {
    color: #007aff;
    display: inline-block;
    margin-bottom: -4px;
  }
`;
