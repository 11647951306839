import { FaCaretDown, FaCaretUp, FaSort } from "react-icons/fa";
import "../css/style.css";

const SortIcon = (props) => {
  if (!props.sorted) {
    return <FaSort className="sortArrowIcon" title="Järjestelemätön" />;
  }
  if (props.sortOrderDesc) {
    return (
      <FaCaretDown
        className="sortArrowIcon"
        title="Järjestely suurimmasta pienimpään (Z-A)"
      />
    );
  }
  if (!props.sortOrderDesc) {
    return (
      <FaCaretUp
        className="sortArrowIcon"
        title="Järjestely pienimmästä suurimpaan (A-Z)"
      />
    );
  }
};

export default SortIcon;
