export const VENUE_QUALITIES = {
  "Hyvä": 0,
  "Välttävä": 1,
  "Ei kunnossa": 2,
};

export const VENUE_MAINTENANCE_STATUS = {
  "Kunnossapito aloitettu": 3,
  "Kunnossapito lopetettu": 4,
};

export const VENUE_QUALITIES_AND_MAINTENANCE_STATUS = {
  ...VENUE_QUALITIES,
  ...VENUE_MAINTENANCE_STATUS,
};

export const VENUE_DIFFICULTIES_WITH_EMPTY = {
  "Helppo": 0,
  "Keskivaikea": 1,
  "Vaikea": 2,
  null: 3,
};

export const VENUE_DIFFICULTIES_WITHOUT_EMPTY = {
  "Helppo": 0,
  "Keskivaikea": 1,
  "Vaikea": 2,
};
