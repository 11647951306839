import React, { useState, useEffect } from "react";
import Table from "./components/Table";
import {
  VENUE_QUALITIES,
  VENUE_MAINTENANCE_STATUS,
  VENUE_QUALITIES_AND_MAINTENANCE_STATUS,
  VENUE_DIFFICULTIES_WITH_EMPTY,
  VENUE_DIFFICULTIES_WITHOUT_EMPTY,
} from "./constants/constants";
import lptApi from "./api/lpt";
import "./css/style.css";
import logo from "./assets/logo.png";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaTimesCircle,
  FaMinusCircle,
  FaDotCircle,
  FaSkiingNordic,
} from "react-icons/fa";
import A2HSProvider from "./components/A2HS";

function App() {
  const [state, setState] = useState({
    data: [],
    totalCount: 0,
    isLoading: true,
    error: false,
  });

  useEffect(() => {
    lptApi
      .get("GetAllVenues")
      .then((res) => {
        const data = res.data.filter((venue) => !venue.Closed);
        setState({
          error: false,
          data: data,
          totalCount: data.length,
          isLoading: false,
        });
      })
      .catch(() =>
        setState({
          data: [],
          isLoading: false,
          error: "Liikuntapaikkojen hakeminen epäonnistui",
        })
      );
  }, []);

  const [selectedVenueTypes, setSelectedVenueTypes] = useState([
    "Latu",
    "Luistelukenttä",
  ]);

  const [selectedVenueQualities, setSelectedVenueQualities] = useState([
    "Hyvä",
    "Välttävä",
    "Ei kunnossa",
    "Kunnossapito aloitettu",
    "Kunnossapito lopetettu",
  ]);

  const [selectedVenueDifficulties, setSelectedVenueDifficulties] = useState([
    "Helppo",
    "Keskivaikea",
    "Vaikea",
    null,
  ]);

  // fetch data and filter based on selected type, quality and difficulty
  const data = React.useMemo(() => {
    let data = state.data;
    // if venue has not been given a value in the database, the value is returned as "" --> changed into null for filtering
    data = data.map((venue) => {
      if (venue.Difficulty === "") {
        return { ...venue, Difficulty: null };
      }

      return venue;
    });

    return data.filter(
      (venue) =>
        selectedVenueTypes.includes(venue.Type) &&
        selectedVenueQualities.includes(venue.Quality) &&
        selectedVenueDifficulties.includes(venue.Difficulty)
    );
  }, [
    state.data,
    selectedVenueTypes,
    selectedVenueQualities,
    selectedVenueDifficulties,
  ]);

  // get count of venues with certain type or quality
  const countVenuesOfType = (type) => {
    return state.data.filter((v) => v.Type === type).length;
  };
  const countVenuesOfQuality = (quality) => {
    return state.data.filter((v) => v.Quality === quality).length;
  };
  const countVenuesOfDifficulty = (difficulty) => {
    return state.data.filter((v) => v.Difficulty === difficulty).length;
  };

  const handleOnChangeType = (e) => {
    if (selectedVenueTypes.includes(e.target.name)) {
      // if list of types already includes checked type, remove it through filtering, returning filtered array
      setSelectedVenueTypes(
        selectedVenueTypes.filter((vt) => vt !== e.target.name)
      );
    } else {
      // if not in list, add it (including old array)
      setSelectedVenueTypes([...selectedVenueTypes, e.target.name]);
    }
  };

  const handleOnChangeQuality = (e) => {
    if (selectedVenueQualities.includes(e.target.name)) {
      setSelectedVenueQualities(
        selectedVenueQualities.filter((vt) => vt !== e.target.name)
      );
    } else {
      setSelectedVenueQualities([...selectedVenueQualities, e.target.name]);
    }
  };

  const handleOnChangeDifficulty = (e) => {
    if (selectedVenueDifficulties.includes(e.target.name)) {
      setSelectedVenueDifficulties(
        selectedVenueDifficulties.filter((vt) => vt !== e.target.name)
      );
    } else {
      setSelectedVenueDifficulties([
        ...selectedVenueDifficulties,
        e.target.name,
      ]);
    }
  };
  // declare columns and special content using react-table
  const columns = React.useMemo(
    () => [
      {
        Header: "Liikuntapaikka",
        accessor: "Place",
        Cell: (venues) => getVenueUrl(venues.row.original),
      },
      {
        Header: "Kunto",
        accessor: "Quality",
        sortType: sortByQuality,
        Cell: (props) => getVenueQuality(props.value),
      },
      {
        Header: "Liikuntamuoto",
        accessor: "Type",
      },
      {
        Header: "Haastavuus",
        accessor: "Difficulty",
        sortType: sortByDifficulty,
        Cell: (props) => getVenueDifficulty(props.value),
      },
      {
        Header: "Selite",
        accessor: "Legend",
      },
      {
        Header: "Huollettu",
        accessor: "MaintenanceDate",
        sortType: sortByDate,
        //accessor: (originalRow) => new Date(originalRow.MaintenanceDate),
        Cell: ({ value }) => getVenueMaintenanceDate({ value }),
      },
      {
        Header: "Tieto päivitetty",
        accessor: (originalRow) => new Date(originalRow.Created),
        sortType: "datetime",
        Cell: ({ value }) =>
          value.toLocaleString("fi-FI", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
      },
    ],
    []
  );

  /* Get place url and return place with url, if there is any */
  const getVenueUrl = (venue) => {
    if (venue.Url) {
      return (
        <>
          <a href={venue.Url} target="_blank" rel="noreferrer">
            {venue.Place}
          </a>
        </>
      );
    } else {
      return <span>{venue.Place}</span>;
    }
  };

  /* Get place quality and return corresponding icon + text */
  const getVenueQuality = (quality) => {
    switch (quality) {
      case "Hyvä":
        return (
          <>
            <FaCheckCircle
              className="qualityIcon"
              title="Hyvä"
              color="#8EB73C"
            />
            <span>{quality}</span>
          </>
        );

      case "Välttävä":
        return (
          <>
            <FaExclamationCircle
              className="qualityIcon"
              title="Välttävä"
              color="#F47D20"
            />
            <span>{quality}</span>
          </>
        );

      case "Ei kunnossa":
        return (
          <>
            <FaTimesCircle
              className="qualityIcon"
              title="Ei kunnossa"
              color="#d70e5f"
            />
            <span>{quality}</span>
          </>
        );

      case "Kunnossapito aloitettu":
        return (
          <>
            <FaDotCircle
              className="qualityIcon"
              title="Kunnossapito aloitettu"
              color="#C0DBF1"
            />
            <span>{quality}</span>
          </>
        );
      case "Kunnossapito lopetettu":
        return (
          <>
            <FaMinusCircle
              className="qualityIcon"
              title="Kunnossapito lopetettu"
              color="#80B7E3"
            />
            <span>{quality}</span>
          </>
        );

      default:
        return "";
    }
  };

  const getVenueDifficulty = (difficulty) => {
    switch (difficulty) {
      case "Helppo":
        return (
          <>
            <FaSkiingNordic
              className="qualityIcon"
              title="Helppo"
              color="#81a52f"
            />
            <span>{difficulty}</span>
          </>
        );

      case "Keskivaikea":
        return (
          <>
            <FaSkiingNordic
              className="qualityIcon"
              title="Keskivaikea"
              color="#d38143"
            />
            <span>{difficulty}</span>
          </>
        );

      case "Vaikea":
        return (
          <>
            <FaSkiingNordic
              className="qualityIcon"
              title="Vaikea"
              color="#d85e55"
            />
            <span>{difficulty}</span>
          </>
        );

      default:
        return (
          <>
            <span>{difficulty}</span>
          </>
        );
    }
  };

  // check for unset MaintenanceDate in database and if so, return —
  const getVenueMaintenanceDate = ({ value }) => {
    if (!value) {
      return "—";
    } else {
      value = new Date(value);
      return value.toLocaleString("fi-FI", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  return (
    <>
      <A2HSProvider />
      <div className="controlPanel">
        <div className="filterGroupHeader">Valitse liikuntamuoto </div>
        {/* Filters based on checkboxes */}
        <div className="venueTypeFilters">
          <label
            id="typeLabelIceRink"
            className="typeLabels"
            title="Luistelukentät"
            style={getInactiveColor(
              selectedVenueTypes.includes("Luistelukenttä")
            )}
          >
            <input
              type="checkbox"
              id="TypeIceRink"
              name="Luistelukenttä"
              checked={selectedVenueTypes.includes("Luistelukenttä")}
              onChange={handleOnChangeType}
            />
            <span>
              Luistelukenttä {"(" + countVenuesOfType("Luistelukenttä") + ")"}
            </span>
          </label>
          <label
            id="typeLabelSkiTrack"
            className="typeLabels"
            title="Ladut"
            style={getInactiveColor(selectedVenueTypes.includes("Latu"))}
          >
            <input
              type="checkbox"
              id="TypeSkiTrack"
              name="Latu"
              checked={selectedVenueTypes.includes("Latu")}
              onChange={handleOnChangeType}
            />
            <span>Latu {"(" + countVenuesOfType("Latu") + ")"}</span>
          </label>
        </div>

        {/* Difficulties which do not contain nulls */}
        <div className="venueDifficultyFilters">
          {Object.keys(VENUE_DIFFICULTIES_WITHOUT_EMPTY).map((difficulty) => {
            let isIncluded =
              selectedVenueTypes.includes("Latu") &&
              selectedVenueDifficulties.includes(difficulty);
            return (
              <label
                key={difficulty}
                id={difficultyToId(difficulty)}
                title={difficulty}
                style={getInactiveColor(isIncluded)}
              >
                <input
                  type="checkbox"
                  name={difficulty}
                  checked={isIncluded}
                  onChange={handleOnChangeDifficulty}
                />
                <span>
                  {difficulty}{" "}
                  {"(" +
                    countVenuesOfDifficulty(difficulty) +
                    "/" +
                    state.totalCount +
                    ")"}{" "}
                </span>
              </label>
            );
          })}
        </div>
        <div className="filterGroupHeader">Valitse liikuntapaikan kunto </div>
        <div className="venueQualityFilters">
          {Object.keys(VENUE_QUALITIES).map((quality) => {
            let isIncluded = selectedVenueQualities.includes(quality);
            return (
              <label
                key={quality}
                id={qualityToId(quality)}
                title={quality}
                style={getInactiveColor(isIncluded)}
              >
                <input
                  type="checkbox"
                  name={quality}
                  checked={isIncluded}
                  onChange={handleOnChangeQuality}
                />
                <span>
                  {quality}{" "}
                  {"(" +
                    countVenuesOfQuality(quality) +
                    "/" +
                    state.totalCount +
                    ")"}
                </span>
              </label>
            );
          })}
        </div>
        <div className="venueQualityFiltersMaintenance">
          {Object.keys(VENUE_MAINTENANCE_STATUS).map((quality) => {
            let isIncluded = selectedVenueQualities.includes(quality);
            return (
              <label
                key={quality}
                id={qualityToId(quality)}
                title={quality}
                style={getInactiveColor(isIncluded)}
              >
                <input
                  type="checkbox"
                  name={quality}
                  checked={isIncluded}
                  onChange={handleOnChangeQuality}
                />
                <span>
                  {quality}{" "}
                  {"(" +
                    countVenuesOfQuality(quality) +
                    "/" +
                    state.totalCount +
                    ")"}
                </span>
              </label>
            );
          })}
        </div>
      </div>
      {state.isLoading ? (
        <div className="tableMessage">Haetaan liikuntapaikkoja...</div>
      ) : (
        <Table columns={columns} data={data} />
      )}
      <footer>
        <img src={logo} alt="Lappeenranta City's logo" />
        <div className="contactBox">
          <span style={{ fontWeight: "bold" }}>Apua sivuston käytössä:</span>
          <span>Liikuntatoimen päivystysnumero</span>
          <span>Puh: +358 40 6657715 (pvm/mpm)</span>
        </div>
        <div className="accessibilityBox">
          <span style={{ color: "#006ec6", textDecoration: "underline" }}>
            <a
              href="https://kuntamfiles.saita.fi/SharedLinks.aspx?accesskey=47587bcb8881a152a145355036830a6ddc6fef7ac24acfcc484f11b6e66bb647&VaultGUID=9A82AB00-5A37-4A7D-9EFF-5F7A7EC1DDBD"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#006ec6", textDecoration: "underline" }}
            >
              Saavutettavuusseloste
            </a>
          </span>
        </div>
      </footer>
    </>
  );
}

const difficultyToId = (difficulty) => {
  switch (difficulty) {
    case "Helppo":
      return "difficultyLabelsEasy";
    case "Keskivaikea":
      return "difficultyLabelsNormal";
    case "Vaikea":
      return "difficultyLabelsHard";
    default:
      return "";
  }
};

const qualityToId = (quality) => {
  switch (quality) {
    case "Hyvä":
      return "qualityLabelsGood";
    case "Välttävä":
      return "qualityLabelsPoor";
    case "Ei kunnossa":
      return "qualityLabelsOutOfOrder";
    case "Kunnossapito aloitettu":
      return "qualityLabelsMaintenanceStarted";
    case "Kunnossapito lopetettu":
      return "qualityLabelsMaintenanceStopped";
    default:
      return "";
  }
};

const sortByQuality = (rowA, rowB, columnId) => {
  return VENUE_QUALITIES_AND_MAINTENANCE_STATUS[rowA.original[columnId]] >
    VENUE_QUALITIES_AND_MAINTENANCE_STATUS[rowB.original[columnId]]
    ? 1
    : -1;
};

const sortByDifficulty = (rowA, rowB, columnId) => {
  return VENUE_DIFFICULTIES_WITH_EMPTY[rowA.original[columnId]] >
    VENUE_DIFFICULTIES_WITH_EMPTY[rowB.original[columnId]]
    ? 1
    : -1;
};

// Handles invalid dates as well
const sortByDate = (rowA, rowB, columnId) => {
  return (new Date(rowA.values[columnId]).getTime() || -Infinity) >
    (new Date(rowB.values[columnId]).getTime() || -Infinity)
    ? 1
    : -1;
};

const getInactiveColor = (checked) => {
  if (checked) {
    return { background: "" };
  } else {
    return { background: "#dddddd", color: "black" };
  }
};

/* // checks if filter is on/off and sets filter style accordingly
const getInactiveColor = (listA, listB, filterA, filterB) => {
  let includedInA = listA.includes(filterA);
  console.log(listA);
  console.log(filterA);
  console.log(includedInA);
  let includedInB = listB.includes(filterB);
  if (listB == undefined || filterB == undefined) {
    if (includedInA) {
      console.log("if listB is null");
      return { background: "" };
    } else {
      return { background: "#dddddd", color: "black" };
    }
  }
  if (includedInA && includedInB) {
    console.log("listB not null");
    let includedInB = listB.includes(filterB);
    if (includedInA && includedInB) {
      return { background: "" };
    } else {
      return { background: "#dddddd", color: "black" };
    }
  }
}; */

export default App;
