import React from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { FaSearch } from "react-icons/fa";
import "../css/style.css";
import SortIcon from "./SortIcon";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "Place",
            desc: false,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );

  // resultCount updates after each filter
  let resultCount;
  resultCount = rows.length;

  // holder for table data to render if there is data to show
  let table;

  if (resultCount > 0) {
    table = (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props into header props to control sorting
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  title={column.Header}
                  id={column.id}
                >
                  <div className="tableHeaderRow">
                    {column.render("Header")}
                    <SortIcon
                      sorted={column.isSorted}
                      sortOrderDesc={column.isSortedDesc}
                    />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} label={cell.column.Header}>
                      <div className="cellContainer">{cell.render("Cell")}</div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div className="tableElements">
      {/* Column filter for place names */}
      <div className="searchBox">
        <div className="searchBoxIconContainer">
          <FaSearch className="searchIcon" title="Suurennuslasi" />
        </div>
        <input
          className="inputSearch"
          onChange={(e) => setFilter("Place", e.target.value)}
          placeholder="Etsi liikuntapaikan nimellä"
        />
      </div>
      <div className="tableMessage">
        Näytetään{" "}
        <span
          className="highlightText"
          style={{ color: resultCount > 0 ? "#006EC6" : "#8a8a8a" }}
        >
          {resultCount}
        </span>{" "}
        liikuntapaikkaa
      </div>
      <div className="tableMessageRight">
        <a
          href="https://kartta.lappeenranta.fi/IMS/?layers=Opaskartta&lon=Hiihtoreitit&cp=6764105,28505860&z=32"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#006ec6",
            textDecoration: "underline",
          }}
        >
          Hiihtolatuverkosto kartalla
        </a>
      </div>
      {table}
    </div>
  );
}

export default Table;
